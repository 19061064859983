import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useAdminsList() {
  const adminListTable = ref([])

  const tableColumns = [
    {
      text: 'ID',
      value: 'uid',
      sortable: false,
      width: '50px',
      align: 'center',
    },
    {
      text: 'Durum',
      value: 'aktif',
      width: '90px',
      align: 'center',
    },
    {
      text: 'Profil',
      value: 'profil',
      width: '120px',
      align: 'center',
    },

    {
      text: 'Kullanıcı Adı',
      value: 'username',
      width: '170px',
      align: 'center',
    },
    {
      text: 'Ad Soyad',
      value: 'adsoyad',
    },

    {
      text: 'Son Giriş',
      value: 'son_giris',
      width: '170px',
    },
    // {
    //   text: 'Kayıt Tarihi',
    //   value: 'kayit_tarihi',
    //   width: '200px',
    // },
    {
      text: '',
      value: 'actions',
      align: 'right min-width-150',
      sortable: false,
      width: '150px',
    },
  ]

  const searchQuery = ref('')
  const customerGroupFilter = ref(null)
  const cariKodFilter = ref(null)
  const idFilter = ref(null)
  const statusFilter = ref(null)
  const nameFilter = ref(null)
  const usernameFilter = ref(null)
  const emailFilter = ref(null)
  const customerFilter = ref(null)
  const updatedFilter = ref(null)

  const totalAdminListTable = ref(0)
  const customerGroupOptions = ref([])
  const roleOptions = ref([])
  const pricelistOptions = ref([])

  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const adminTotalLocal = ref([])
  const selectedRows = ref([])

  const statusOptions = [
    { title: 'Pasif', value: '0' },
    { title: 'Aktif', value: '1' },
  ]

  // fetch data
  const fetchAdmins = () => {
    store
      .dispatch('app-admin/fetchAdmins', {
        q: searchQuery.value,
        options: options.value,
        uid: idFilter.value,
        aktif: statusFilter.value,
        cust_group: customerGroupFilter.value,
        cari_kod: cariKodFilter.value,
        adsoyad: nameFilter.value,
        username: usernameFilter.value,
        email: emailFilter.value,
        customer: customerFilter.value,
        updated: updatedFilter.value,
      })
      .then(response => {
        adminListTable.value = response.result
        totalAdminListTable.value = Number(response.pagination.totalRec)
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchCustomerGroup = () => {
    store.dispatch('app-admin/fetchCustomerGroup').then(response => {
      const data = response.response

      customerGroupOptions.value = data.map(key => ({ text: key.stext, value: key.group_code }))
    })
  }

  const fetchRole = () => {
    store.dispatch('app-admin/fetchRole').then(response => {
      const data = response.detail

      roleOptions.value = data.map(key => ({ text: key.profile_name, value: key.profile_id, type: key.profile_type }))
    })
  }

  const fetchPricelist = () => {
    store.dispatch('app-admin/fetchPricelist').then(response => {
      const data = response.response

      pricelistOptions.value = data.map(key => ({ text: key.stext, value: key.group_code }))
    })
  }

  watch(
    [
      searchQuery,
      idFilter,
      customerGroupFilter,
      cariKodFilter,
      statusFilter,
      nameFilter,
      usernameFilter,
      emailFilter,
      customerFilter,
      updatedFilter,
      options,
    ],
    () => {
      loading.value = true
      selectedRows.value = []
      fetchAdmins()
    },
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAdminRoleVariant = role => {
    if (role === 'plasiyer') return 'warning'
    if (role === 'customer') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveAdminStatusVariant = aktif => {
    if (aktif === '2') return 'warning'
    if (aktif === '1') return 'success'
    if (aktif === '3') return 'secondary'

    return 'primary'
  }

  const resolveAdminTotalIcon = total => {
    if (total === 'Total Admins') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Admins') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Admins') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Admins') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    adminListTable,
    tableColumns,
    searchQuery,
    idFilter,
    customerGroupFilter,
    cariKodFilter,
    statusFilter,
    nameFilter,
    usernameFilter,
    emailFilter,
    customerFilter,
    updatedFilter,
    totalAdminListTable,
    statusOptions,
    customerGroupOptions,
    roleOptions,
    pricelistOptions,
    loading,
    options,
    adminTotalLocal,
    selectedRows,
    fetchAdmins,
    fetchPricelist,
    fetchCustomerGroup,
    fetchRole,
    resolveAdminRoleVariant,
    resolveAdminStatusVariant,
    resolveAdminTotalIcon,
  }
}
